<template>
  <div v-loading="loading" class="vnc">
    <div v-show="showTalk" class="left">
      <div class="title">
        所有会话
      </div>
      <div
        v-for="(item, i) in talkList"
        :key="i"
        class="list"
        :class="{ active: i === activeColor }"
        @dblclick="clickHandle(i,item)"
        @contextmenu.prevent="rightClick(item, $event)"
      >
        <svg-icon :icon-class="[i === activeColor ? 'talk-on' : 'talk']" />
        <span>{{ item.ip }}</span>
      </div>
    </div>
    <div class="middle">
      <div class="control" @click="showHandle">
        <img src="@/assets/icon/arrow.png" alt="">
      </div>
    </div>
    <div class="right">
      <div class="header">
        <el-input v-model="hostname" placeholder="主机名" />
        <el-input v-model="port" class="port" placeholder="端口" />
        <el-input v-model="username" placeholder="用户名" />
        <el-input v-model="password" type="password" placeholder="密码" />
        <el-checkbox v-model="remember" label="记住会话" size="small" style="z-index:0;margin-right:1%" @change="rememberHandle" />
        <el-button @click="linkHandle"><svg-icon icon-class="link" />连接</el-button>
      </div>
      <div class="vnc-content">
        <div v-if="waitLoading" class="wait-loading">
          <div>
            <img src="@/assets/icon/novnc-loading.png" alt="">
            <div class="wait-loading-text">等待连接...</div>
          </div>
        </div>
      </div>
      <!-- <div id="screen" style="width:100%;height:100%"></div> -->
    </div>
    <div v-show="menuVisible" id="contextmenu" class="vncMenu">
      <div class="contextmenu-item" @click="deleteSession(CurrentRow)">
        <svg-icon icon-class="uninstall" />
        删除
      </div>
    </div>
  </div>
<!-- <div id="screen" style="width:100%;height:100%"></div> -->
</template>

<script>
import store from '@/store'
import Guacamole from 'guacamole-common-js'
import { addSession, querySession, deleteSession } from '@/api/application'
import { ElMessage } from 'element-plus'
// import elementResize from 'element-resize-detector'
export default {
  data() {
    return {
      rememberPsdVisible: false,
      remember: false,
      waitLoading: true,
      isReloadData: true,
      sessionSwitch: false,
      vncSwitch: true,
      vncContentStyle: {
        width: '',
        height: ''
      },
      uId: '',
      connection: '',
      token: '',
      loading: true,
      showTalk: true,
      hostname: '',
      port: '5901',
      username: '',
      password: '',
      activeColor: 0,
      talkList: [],
      socket: '',
      canvasWidth: '1920',
      canvasHeight: '1040',
      client: '',
      tunnels: '',
      menuVisible: false,
      CurrentRow: '',
      keyboard: null
    }
  },
  watch: {
    hostname() {
      this.sessionSwitch = true
    },
    port() {
      this.sessionSwitch = true
    },
    username() {
      this.sessionSwitch = true
    },
    password() {
      this.sessionSwitch = true
    },
    talkList: {
      handler() {
        console.log('监控到变化')
        this.reload()
      },
      deep: true
    }
  },
  mounted() {
    this.uId = store.getters.uId
    this.getSession()
    // this.connect()
  },
  beforeUnmount() {
    if (this.client !== '') {
      this.client.disconnect()
    }
    this.removeKeyboardListeners()
    // this.tunnels.disconnect()
  },
  methods: {
    handleClose() {
      this.rememberPsdVisible = false
    },
    // 记住密码
    rememberHandle(val) {
      if (val === true) {
        this.remember = true
      } else {
        this.remember = false
      }
      this.sessionSwitch = true
    },
    // 右键
    rightClick(row, event) {
      this.testModeCode = row.testModeCode
      this.menuVisible = false // 先把模态框关死，目的是 第二次或者第n次右键鼠标的时候 它默认的是true
      this.menuVisible = true // 显示模态窗口，跳出自定义菜单栏
      event.preventDefault() // 关闭浏览器右键默认事件
      this.CurrentRow = row
      var menu = document.querySelector('.vncMenu')
      this.styleMenu(menu, event)
    },
    foo() {
      // 取消鼠标监听事件 菜单栏
      this.menuVisible = false
      window.removeEventListener('click', this.foo) // 关掉监听，
    },
    styleMenu(menu, event) {
      var y = event.clientY
      var x = event.clientX
      menu.style.left = x + 'px'
      window.addEventListener('click', this.foo) // 给整个document新增监听鼠标事件，点击任何位置执行foo方法
      menu.style.top = y + 'px'
    },
    // 删除会话
    deleteSession(row) {
      const listSessionId = []
      listSessionId.push(row.id)
      const data = {
        type: 'vnc',
        listSessionId: listSessionId
      }
      deleteSession(data).then((response) => {
        if (response.meta.status === 204) {
          ElMessage.success('删除成功')
          this.getSession()
        } else {
          ElMessage.error(response.meta.msg)
        }
      })
    },
    reload() {
      this.isReloadData = false
      this.$nextTick(() => {
        this.isReloadData = true
      })
    },
    connect() {
      if (!this.vncSwitch) {
        // 如果已经存在则放弃连接
        this.client.disconnect()
        document.getElementsByClassName('vnc-content')[0].innerHTML = ''
      }
      // const reg = /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])$/
      // const regPort = /^(([0-9]|[1-9]\d{1,3}|[1-5]\d{4}|6[0-5]{2}[0-3][0-5]))$/
      if (this.hostname === '' || this.username === '' || this.password === '' || this.port === '') {
        ElMessage.warning('主机名、端口、用户名、密码均不可为空！请填写或者选择会话！')
        return
      // } else {
      //   if (!reg.test(this.hostname)) {
      //     ElMessage.warning('请填写正确的主机名！')
      //     return
      //   }
      //   if (!regPort.test(this.port)) {
      //     ElMessage.warning('请填写正确的端口号！')
      //     return
      //   }
      }
      this.vncSwitch = false
      this.loading = true
      const Base64 = require('js-base64').Base64
      const data = {
        hostname: this.hostname,
        username: this.username,
        password: this.password,
        port: this.port,
        regionId: window.localStorage.regionId
      }
      this.connection = Base64.encode(JSON.stringify(data))
      this.token = sessionStorage.desktopToken
      const connect = 'connect=' + this.connection + '&height=500&width=500&token=' + this.token
      // const socket = 'wss:' + location.host + '/vncSocket/ws/vnc/vncConnect'
      var socket
      var ishttps = document.location.protocol == 'https:'
      if (ishttps) {
        socket = 'wss:' + location.host + '/vncSocket/ws/vnc/vncConnect'
      } else {
        socket = 'ws:' + location.host + '/vncSocket/ws/vnc/vncConnect'
      }
      const tunnels = new Guacamole.WebSocketTunnel(socket)
      var client = new Guacamole.Client(tunnels)
      this.client = client
      this.tunnels = tunnels
      document.getElementsByClassName('vnc-content')[0].appendChild(client.getDisplay().getElement())
      document.getElementsByTagName('canvas').item(0).style = 'z-index: 1'
      document.getElementsByTagName('canvas').item(1).style = 'z-index: 1'
      document.getElementsByTagName('canvas').item(0).style.width = this.canvasWidth + 'px'
      document.getElementsByTagName('canvas').item(0).style.height = this.canvasHeight + 'px'
      client.connect(connect)
      this.$nextTick(() => {
        this.waitLoading = false
      })
      // client.disconnect()
      var mouse = new Guacamole.Mouse(client.getDisplay().getElement())
      mouse.onmousedown =
          mouse.onmouseup =
              mouse.onmousemove = function(mouseState) {
                client.sendMouseState(mouseState)
              }

      // Keyboard
      this.addKeyboardListeners(client)
      this.loading = false
    },
    addKeyboardListeners(client) {
      this.keyboard = new Guacamole.Keyboard(document)

      this.keyboard.onkeydown = function(keysym) {
        client.sendKeyEvent(1, keysym)
      }

      this.keyboard.onkeyup = function(keysym) {
        client.sendKeyEvent(0, keysym)
      }
    },
    removeKeyboardListeners() {
      if (this.keyboard) {
        this.keyboard.onkeydown = null
        this.keyboard.onkeyup = null
      }
    },
    clickHandle(i, item) {
      this.sessionSwitch = true
      this.activeColor = i
      this.hostname = item.ip
      this.port = item.port
      this.password = item.pwd
      this.username = item.username
      this.$nextTick(() => {
        this.connect()
      })
    },
    showHandle() {
      this.showTalk = !this.showTalk
    },
    getSession() {
      const data = {
        type: 'vnc',
        uid: this.uId
      }
      querySession(data).then((response) => {
        this.talkList = response.data
        this.loading = false
      })
    },
    async linkHandle() {
      const reg = /^((25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])\.){3}(25[0-5]|2[0-4][0-9]|1[0-9][0-9]|[1-9][0-9]|[0-9])$/
      const regPort = /^(([0-9]|[1-9]\d{1,3}|[1-5]\d{4}|6[0-5]{2}[0-3][0-5]))$/
      if (this.hostname === '' || this.username === '' || this.password === '' || this.port === '') {
        ElMessage.warning('主机名、端口、用户名、密码均不可为空！请填写或者选择会话！')
        return
      } else {
        if (!reg.test(this.hostname)) {
          ElMessage.warning('请填写正确的主机名！')
          return
        }
        if (!regPort.test(this.port)) {
          ElMessage.warning('请填写正确的端口号！')
          return
        }
      }
      const session = {
        ip: this.hostname,
        username: this.username,
        pwd: this.password,
        port: this.port,
        type: 'vnc',
        uid: this.uId,
        remember: this.remember.toString()
      }
      if (this.sessionSwitch) {
        addSession(session).then((response) => {
          this.getSession()
        })
        this.sessionSwitch = false
      }
      // 连接后就不应该再连接了，除非得到释放
      await this.connect()
    }
  }
}
</script>
<style lang="less" scoped>
.vnc{
  display: flex;
  width: 100%;
  height: 100%;
  .dialogs-content{
    padding: 20px;
    text-align: center;
  }
  ::v-deep .el-dialog{
      border-radius: 20px;
    }
    ::v-deep .el-dialog__header{
      border-bottom: 1px solid #d8d8d8;
      background: #f5f5f5;
      border-top-left-radius: 20px;
      border-top-right-radius: 20px;
    }
    ::v-deep .el-dialog__body{
      padding: 0;
    }
    ::v-deep .el-icon{
      color: #333;
    }
  .vncMenu {
    position: fixed;
    background-color: #fff;
    width: 160px;
    padding: 0 10px;
    height: 31.5px;
    font-size: 12px;
    color: #313a46;
    border-radius: 4px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    border-radius: 3px;
    border: 1px solid #e8eef3;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
    white-space: nowrap;
    z-index: 10000;
    .svg-icon {
      width: 16px;
      height: 16px;
      vertical-align: middle;
      margin-right: 11px;
    }
  }
  .left{
    min-width: 240px;
    max-width: 400px;
    height: 100%;
    overflow: auto;
    color: #313A46;
    padding-top: 20px;
    padding-left: 20px;
    .active{
      background: #F2F9FF;
      color: #2385FF;
    }
    .title{
      font-size: 16px;
font-family: PingFang SC;
font-weight: bold;
// color: #313A46;
line-height: 25px;
padding-bottom: 23px;
    }
    .list:hover{
      background: #F2F9FF;
      color: #2385FF;
    }
    .list {
      font-size: 14px;
font-family: PingFang SC;
font-weight: 500;
padding: 6px 0 6px 6px;
span {
  vertical-align: middle;
}
    }
    .svg-icon {
      margin-right: 8px;
      width: 16px;
      height: 16px;
      vertical-align: middle;
    }
  }
  .middle{
    width: 12px;
    height: 100%;
background: #F5F5F5;
border: 1px solid #E8E8E8;
// padding-top: 20%;
display: flex;
    align-items: center;
.control{
  width: 10px;
height: 80px;
padding-top: 24px;
background: #A0E1F9;
}
  }
  .right{
    width: 100%;
    height: 100%;
    // min-width: 600px;
    .vnc-content{
      width: 100%;
      height: calc(100% - 80px);
      .wait-loading{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .wait-loading-text{
          padding: 23px;
          font-size: 16px;
          color: #606060;
          text-align: center;
        }
      }
    }
    .header{
      // display: flex;
      // max-width: 1000px;
      padding: 20px 0 20px 20px;
      line-height: 40px;
      ::v-deep .el-input {
          display: inline;
          margin-right: 1%;
          --el-input-focus-border: #dcdfe6;
        }
        ::v-deep .el-input__inner {
          display: inline;
          width: 20%;
          border-radius: 2px;
          height: 34px;
          line-height: 34px;
        }
        .port{
          ::v-deep .el-input__inner {
          width: 12%;
        }
        }
      ::v-deep .el-button {
      margin-right: 1%;
      background-color: #437aec;
      color: #fff;
      padding: 10px 12px 7px 12px;
      border-radius: 4x;
      min-height: 30px;
    }
    .svg-icon {
      width: 15px;
      height: 15px;
      vertical-align: middle;
      margin-right: 6px;
    }
    }
  }
}
</style>
